import userColored from "../../public/images/NoPhoto.png";
import boyIcon from "../../public/images/boy.svg";
import girlIcon from "../../public/images/girl.svg";
import { confirmAlert } from "react-confirm-alert";
import "./confirmAlert.css";
import formatMsg from "./formatMsg";
import { getItem } from "../libs/encryptedStorage";
import dayjs from "../libs/day";
import { useSelector } from 'react-redux';
import imageCompression from "browser-image-compression";
import Firebase from "firebase/compat/app";
import store from "../redux/store";
import actions from "../redux/actionType";
import colors from "../libs/colorFactory";
import { floor } from "mathjs";
import { notification } from "./notification";
import { DatePickerProps } from "antd";
import { timeZones } from "./momentTimezone";
import bugsnagClient from "@bugsnag/js";
import { Row } from 'antd'
import { RightOutlined } from "@ant-design/icons";
import { useRouter } from 'next/navigation';
import { Auth0Client } from '@auth0/auth0-spa-js';
import { oktaRedirectUrl } from '../libs/fbConfig';
const superagent = require("superagent");
import { useDispatch } from 'react-redux';
import { loginApi } from "../firebase-api/login";
export default class Helper {
    constructor() {

    }


    static escapeRegExp(string: string): string {
        return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
    }

    static replaceAll = (str: string, term: string, replacement: string) => {
        return str.replace(new RegExp(Helper.escapeRegExp(term), "g"), replacement);
    }

    static getEncodedValue = (val: string) => {
        var encodedEmail = Helper.replaceAll(val, "@", "%40");
        return encodedEmail = Helper.replaceAll(encodedEmail, ".", "%2E");
    }

    static getDecodedValue = (val: string) => {
        var decodedEmail = Helper.replaceAll(val, "%40", "@");
        return decodedEmail = Helper.replaceAll(decodedEmail, "%2E", ".");
    }

    static getUrlParam = (key: string) => {
        if (typeof window !== 'undefined') {
            const urlParams = new URLSearchParams(window.location.search);
            return urlParams.get(key);
        }
    };

    static setUrlParam = (key: string, value: string) => {
        if (typeof window !== 'undefined') {
            const url = new URL(window.location.href);
            url.searchParams.set(key, value);
            window.history.replaceState(null, null, url);
        }
    };
    static checkUrlParam = (value: any, defaultValue: any, valueType: "number" | "string" | null = null) => {
        if (value && value != "null" && value != "undefined") {
            if (valueType === "number") {
                return Number(value);
            }
            if (valueType === "string") {
                return String(value);
            }
            return value;
        }
        return defaultValue
    };
    static getProfileImageUrl = (obj: any) => {
        let gender = obj?.gender?.toLowerCase();
        let icon = userColored;
        if (obj?.profileImageUrl || obj?.profilePhoto) {
            return obj?.profileImageUrl || obj?.profilePhoto;
        } else {
            switch (gender) {
                case "male":
                    icon = boyIcon;
                    break;
                case "female":
                    icon = girlIcon;
                    break;

                default:
                    icon = userColored;
            }
            return icon.src
        }
    }
    static getWeekDays = () => {
        return [
            "Sunday", "Monday", "Tuesday", "Wednessday", "Thursday", "Friday", "Saturday"
        ]
    }

    static getBase64 = (file: any): Promise<string> =>
        new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result as string);
            reader.onerror = (error) => reject(error);
        });


    static confirmAlert = (confirmAlertObject) => {
        const { title, message, yes, no, yesOnClick, noOnClick = () => { }, closeOnClickOutside = false, } = confirmAlertObject;
        confirmAlert({
            closeOnClickOutside: closeOnClickOutside,
            title: formatMsg(title),
            message: formatMsg(message),
            buttons: [
                {
                    label: formatMsg(yes),
                    onClick: yesOnClick,
                },
                {
                    label: formatMsg(no),
                    onClick: noOnClick,
                },
            ],
        });
    }

    static getDefaultLanguageKey = () => {
        let storedKey = getItem("lng");
        return storedKey ? storedKey : "en";
    };

    static getSuperagentBody = (obj, firebase, godMode?: any) => {
        obj.platform = "web";
        // obj.version = packageJson.version;
        obj.updatedOn = dayjs().valueOf();
        // obj.createdOn = dayjs().valueOf();

        if (firebase.teacher) obj.updatedBy = firebase.teacher.name;
        if (firebase.parent) obj.updatedBy = firebase.parent.name;

        if (godMode) obj.updatedBy = "GOD";

        return obj;
    };

    static getSelectorState = (keys: string[]) => {
        const result: any = {};
        const allSlices = useSelector((state: any) => {
            const slices: any = {};
            keys.forEach(key => {
                slices[key] = state[key];
            });
            return slices;
        });

        keys.forEach(key => {
            result[key] = allSlices[key];
        });

        const login = useSelector((state: any) => state.login);
        result.firebase = login.firebase;

        return result;
    }

    static getPathToStorage(contentType, firebase, mediaType?: any) {
        let storagePath;
        switch (contentType) {
            case "application/octet-stream":
                storagePath = firebase.sbp + "/media/file/";
                break;
            case "text/csv":
                storagePath = firebase.sbp + "/media/file/";
                break;
            case "image/jpeg":
                storagePath = firebase.sbp + "/media/images/";
                break;
            case "image/png":
                storagePath = firebase.sbp + "/media/images/";
                break;
            case "image/bmp":
                storagePath = firebase.sbp + "/media/images/";
                break;
            case "image/gif":
                storagePath = firebase.sbp + "/media/images/";
                break;
            case "image/webp":
                storagePath = firebase.sbp + "/media/images/";
                break;
            case "video/mp4":
                storagePath = firebase.sbp + "/media/videos/";
                break;
            case "video/3gpp":
                storagePath = firebase.sbp + "/media/videos/";
                break;
            case "video/mpeg":
                storagePath = firebase.sbp + "/media/videos/";
                break;
            case "video/quicktime":
                storagePath = firebase.sbp + "/media/videos/";
                break;
            case "audio/mp3":
                storagePath = firebase.sbp + "/media/audio/";
                break;
            default:
                storagePath = firebase.sbp + "/media/images/";
        }

        if (contentType && contentType.includes("application")) {
            storagePath = firebase.sbp + "/media/file/";
        }

        if (!storagePath && mediaType && mediaType.toLowerCase() === "video") {
            storagePath = firebase.sbp + "/media/videos/";
        }

        return storagePath;
    }

    static getMediaType(contentType, file) {
        let mediaType;
        let incomingContentType = contentType;
        if (!incomingContentType) {
            incomingContentType = file.type;
        }

        switch (incomingContentType) {
            case "application/octet-stream":
                mediaType = "file";
                break;
            case "text/csv":
                mediaType = "file";
                break;
            case "image/jpeg":
                mediaType = "image";
                break;
            case "image/png":
                mediaType = "image";
                break;
            case "image/bmp":
                mediaType = "image";
                break;
            case "image/gif":
                mediaType = "image";
                break;
            case "image/webp":
                mediaType = "image";
                break;
            case "video/mp4":
                mediaType = "video";
                break;
            case "video/3gpp":
                mediaType = "video";
                break;
            case "video/mpeg":
                mediaType = "video";
                break;
            case "video/quicktime":
                mediaType = "video";
                break;
            case "audio/mp3":
                mediaType = "audio";
                break;
            default:
                mediaType = "image";
        }

        if (incomingContentType && incomingContentType.includes("application")) {
            mediaType = "file";
        }

        return mediaType;
    }

    static convertImage = (selectedFile) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            const canvas = document.createElement('canvas');
            const ctx = canvas.getContext('2d');

            reader.onload = (e) => {
                const img: any = new Image();
                img.onload = () => {
                    canvas.width = img.width;
                    canvas.height = img.height;
                    ctx.drawImage(img, 0, 0, img.width, img.height);

                    // Convert the canvas content to PNG
                    canvas.toBlob((blob) => {
                        resolve(new File([blob], selectedFile.name + '.png', { type: 'image/png' }));
                    }, 'image/png');
                };

                img.src = e.target.result;
            };
            reader.readAsDataURL(selectedFile);
        });
    };

    static async checkFileSizeAndCompress(originalFile, options, firebase) {
        let compressedFilePromise;
        if (
            (originalFile &&
                originalFile.size &&
                originalFile.size <= 1000000) ||
            (firebase.schoolName &&
                firebase.schoolName.toLowerCase().includes("woodland") &&
                originalFile &&
                originalFile.size &&
                originalFile.size <= 5000000)
        ) {
            return originalFile;
            // compressedFilePromise = new Promise((resolve, reject) => {
            //     resolve(originalFile)
            // })
        } else {
            let convertedFile;
            if (originalFile.type && originalFile.type.includes("jpeg")) {
                convertedFile = await Helper.convertImage(originalFile);
            }

            if (convertedFile) {
                compressedFilePromise = imageCompression(convertedFile, options);
            } else {
                compressedFilePromise = imageCompression(originalFile, options);
            }
            return compressedFilePromise;
        }
    }

    static getAttachmentDownloadUrl(
        attachmentRef: any,
        file: any,
        metadata: { contentType: any; cacheControl: any; },
        activityId?: any,
        urls?: any,
        singleFile?: { type: any; },
        firebase?: any,
        branchPath?: any,
        fileName?: any,
        thumbnailSource?: any,
        index?: any,
        studentIds?: any,
        base64String?: any,
        ogFileName?: any,
        moreThanOne?: number
    ) {
        return new Promise(function (resolve, reject) {
            let mediaType;
            let atchRef = attachmentRef.put(file, metadata);
            if (base64String) {
                mediaType = "file";
                atchRef = attachmentRef.putString(base64String);
            }
            atchRef.on(
                Firebase.storage.TaskEvent.STATE_CHANGED,
                function (snapshot) {
                    mediaType = Helper.getMediaType(
                        snapshot.metadata.contentType ? snapshot.metadata.contentType : undefined,
                        singleFile
                    );
                    var progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
                    store.dispatch({
                        type: actions.ATTACHHMENT_UPLOAD_PROGRESS,
                        attachmentProgress: moreThanOne ? progress && progress > 95 ? moreThanOne : 0 : progress,
                        moreThanOne: moreThanOne ? true : false,
                    });
                },
                function (error) {
                    console.log("error", error);
                    reject(error);
                },
                function () {
                    var p2 = attachmentRef.getDownloadURL().then(function (downloadURL) {
                        urls.push({
                            path: downloadURL,
                            type: mediaType,
                            name: Helper.getPathToStorage(singleFile.type, firebase, mediaType) + fileName,
                            fileName: ogFileName,
                        });
                        resolve(p2);
                    });
                }
            );
        });
    }

    static async getAttachedMediaPath(storagePath: string, files: any, firebase: any) {
        var urls = [];
        var promiseList = [];
        let branchPath = firebase.sbp;
        files.fileList.forEach(function (singleFile, index) {
            var originalFile =
                singleFile.originFileObj && singleFile.originFileObj.name
                    ? singleFile.originFileObj
                    : singleFile;
            const options = {
                maxSizeMB: 20,
                maxWidthOrHeight: 1200,
                useWebWorker: true,
            };
            var metadata = {
                contentType: singleFile.type,
                cacheControl: "public,max-age=604800",
            };
            const storage = firebase.secondaryStorage;
            const storageRef = storage.ref();
            let fileName = new Date().getTime() + originalFile.name;
            let ogFileName = originalFile.name;
            const attachmentRef = storageRef.child(Helper.getPathToStorage(singleFile.type, firebase) + fileName);
            let compressedFilePromise = Helper.checkFileSizeAndCompress(originalFile, options, firebase);

            let p2 = compressedFilePromise
                .then((fileRes) => {
                    let file = fileRes;
                    return Helper.getAttachmentDownloadUrl(
                        attachmentRef,
                        file,
                        metadata,
                        null,
                        urls,
                        singleFile,
                        firebase,
                        branchPath,
                        fileName,
                        null,
                        index,
                        null,
                        originalFile.base64String ? originalFile.base64String : undefined,
                        ogFileName,
                        files.fileList.length > 1 ? Number(100 / files.fileList.length) : 0
                    )
                })
                .catch((err) => {
                    return Helper.getAttachmentDownloadUrl(
                        attachmentRef,
                        originalFile,
                        metadata,
                        null,
                        urls,
                        singleFile,
                        firebase,
                        branchPath,
                        fileName,
                        null,
                        index,
                        null,
                        originalFile.base64String ? originalFile.base64String : undefined,
                        ogFileName,
                        files.fileList.length > 1 ? Number(100 / files.fileList.length) : 0
                    )
                });
            promiseList.push(p2);
            // store.dispatch({
            //     type: actions.ATTACHHMENT_UPLOAD_PROGRESS,
            //     attachmentProgress: files.fileList.length > 1 ? Number(100 / files.fileList.length) : 0,
            //     allFilesUploaded: false,
            // });

        })
        return Promise.all(promiseList)
            .then(function () {
                store.dispatch({
                    type: actions.ATTACHHMENT_UPLOAD_PROGRESS,
                    attachmentProgress: 100,
                    allFilesUploaded: true,
                });
                return urls;
            })
            .catch((error) => {
                throw error;
            });
    }

    static getPackageTypeList() {
        return ["Activity Camp", "Full day camp", "Half day camp", "Workshop", "Party", "Events & Shows"]
    }

    static getTeacherList() {
        return JSON.parse(getItem("teacherList"))?.filter((item) => !item.deleted);
    }

    static getStudentList() {
        return JSON.parse(getItem("studentList"))?.filter((item) => !item.deleted);
    }
    static getActiveStudentList() {
        return JSON.parse(getItem("studentList"))?.filter((item) => !item.deleted && item.status.toLowerCase() === "active");
    }

    static getStudentObj(id) {
        return JSON.parse(getItem("studentList"))?.find((item) => item.id === id);
    }

    static getStaffObj(id) {
        return JSON.parse(getItem("teacherList"))?.find((item) => item.id === id);
    }

    static getStatustag(status) {
        let backgorundColor;
        switch (status?.toLowerCase()) {
            case "active":
                backgorundColor = colors.color_activeColor;
                break;
            case "withdrawn":
                backgorundColor = "#F8CB5D";
                break;
            default:
                backgorundColor = colors.color_activeColor;
        }

        return <div
            style={{
                backgroundColor: backgorundColor,
                color: colors.color_white,
                height: 28,
                padding: "0 19px",
                borderRadius: 5,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "fit-content",
                fontSize: "var(--font-size-14)",
                fontWeight: 600
            }}
        >
            {formatMsg(status)}
        </div>;
    }

    static getDateRanges() {
        return ["thisMonth", "lastMonth", "thisYear", "lifetime", "custom"];
    }

    static getAmountStatusTag(status) {
        let bgColor = "#EBF9ED";
        let borderColor = "#59C069";

        switch (status.toLowerCase()) {
            case "partiallypaid":
            case "due":
            case "failed":
            case "draft":
                bgColor = "#FEF8EA";
                borderColor = "#F8CB5D";
                break;
            case "cancelled":
            case "partiallyoverdue":
            case "overdue":
            case "pending":
            case "inactive":
                bgColor = "#FFF4F6";
                borderColor = "#FA91AA";
                break;
        }
        return <div
            style={{
                color: colors.color_primary,
                border: `0.5px solid ${borderColor}`,
                background: bgColor,
                minHeight: 18,
                fontSize: 14,
                fontWeight: 500,
                paddingLeft: 12,
                paddingRight: 12,
                borderRadius: 3,
                width: "fit-content",
                display: "flex",
                alignItems: "center"
            }}
        >
            {formatMsg(status)}
        </div>
    }
    static dateFormat(firebase) {
        return firebase?.schoolConfig?.dateFormat || "DD MMM YY"
    }
    static sortIntegerValue = (a, b, key) => {
        if (!a || a[key] == null || a[key] == undefined) {
            return 1;
        }

        if (!b || b[key] == null || b[key] == undefined) {
            return -1;
        }

        return a[key] - b[key];
    };

    static sortStringValue = (a, b, key, key2?) => {
        let valueA, valueB;

        if (key2) {
            valueA = a && a[key] ? a[key][key2] : null;
            valueB = b && b[key] ? b[key][key2] : null;
        } else {
            valueA = a ? a[key] : null;
            valueB = b ? b[key] : null;
        }

        if (valueA === null) return 1;
        if (valueB === null) return -1;

        return valueA?.toString().localeCompare(valueB?.toString());
    };

    static decimalFormat = (number: any) => {
        number = floor(Number(number), 2);
        return number.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
    static getAgeRangeName(ageRange, packageReducer) {
        let obj = packageReducer?.ageRange?.find((item) => item.id === ageRange);
        return this.getAgeRangeFormat(obj)
    }

    static getAgeRangeFormat(obj) {
        return obj && obj.min.year + "y" + " " + obj.min.month + "m" + " - " + obj.max.year + "y" + " " + obj.max.month + "m"
    }

    static calculateIntervals(startDate, noOfWeeks) {
        const start = dayjs(startDate);
        const end = start.add(noOfWeeks, 'weeks');

        const days = end.diff(start, 'day') || 1;
        const weeks = end.diff(start, 'week') || 1;
        const months = end.diff(start, 'month') || 1;
        const years = end.diff(start, 'year') || 1;

        let weekends = 0;
        let current = start;

        while (current.isSameOrBefore(end)) {
            if (current.day() === 0 || current.day() === 6) {
                weekends++;
            }
            current = current.add(1, 'day');
        }

        return { DAILY: days, WEEK: weeks, WEEKEND: weekends, MONTH: months, YEARLY: years };
    }

    static shortLinkUrl(copyText) {

        let endPointUrl =
            "https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=AIzaSyB775InMuZ5ylr7usQKlDeRFMVDFKnG8UE";

        let obj = {
            dynamicLinkInfo: {
                domainUriPrefix: "https://illumine.page.link",
                link: copyText,
            },
            suffix: {
                option: "SHORT",
            },
        };

        var p1 = new Promise(function (resolve, reject) {
            superagent
                .post(endPointUrl)
                .send(obj)
                .set("accept", "json")
                .end((err, res) => {
                    if (res) {
                        resolve(res);
                    } else if (err) {
                        reject(err);
                    }
                });
        });

        return p1;
    }

    static handleCopyToClipboardError(err, text) {
        switch (err?.name) {
            case 'PermissionDeniedError':
                console.error('Clipboard access denied. Please grant permission.');
                notification("error", formatMsg("copy.permissionDenied"))
                break;
            case 'SecurityError':
                console.error('Clipboard access is only available in secure contexts (HTTPS).');
                break;
            case 'ClipboardWriteFailed':
                console.error('Failed to write text to clipboard.');
                break;
            case 'DataTransferError':
                console.error('Data transfer to clipboard failed.');
                break;
            case 'UnsupportedClipboardOperation':
                console.error('Clipboard operation is not supported.');
                break;
            default:
                console.error('An unknown error occurred:', err);
                break;
        }
        let message = formatMsg("copy.failedToCopy") + " " + formatMsg("copy.manually") + "\n\n" + text
        alert(message)
    }

    static copyToClipboard(text) {
        navigator.clipboard.writeText(text).then(() => {
            notification("success", formatMsg("notification.copyToClip"));
        }).catch((err) => {
            this.handleCopyToClipboardError(err, text)
        })

    };

    static getObjById(id, list) {
        if (!id || !list) {
            return;
        }
        return list?.find((item) => item?.id === id);
    }

    static searchByKey(list, key, value) {
        let text = value
            .trim()
            .toLowerCase()
            .replace(/[.*+?^${}()|[\]\\]/g, "\\$&");

        if (!text || text === "") {
            return list;
        } else {
            return list.filter((item) => {
                if (item[key] && item[key].toLowerCase().match(text)) {
                    return true;
                }
            });
        }
    };

    static dateRangeFilter(start1, end1, start2, end2, granularity) {
        const latestStart = start1.isAfter(start2) ? start1 : start2;
        const earliestEnd = end1.isBefore(end2) ? end1 : end2;

        if (latestStart.isBefore(earliestEnd, granularity) || latestStart.isSame(earliestEnd, granularity)) {
            return true;
        } else {
            return false;
        }
    };

    static customWeekStartEndFormat: DatePickerProps['format'] = (value) =>
        `${dayjs(value).startOf('week').format("DD MMMM YYYY")} ~ ${dayjs(value).endOf('week').format("DD MMMM YYYY")}`;

    static getAge = (dob) => {
        let birthDate = dob ? dayjs(dob) : undefined;

        if (birthDate) {
            let currentDate = dayjs().add(1, 'days');
            let studentAge = dayjs.duration(currentDate.diff(birthDate));
            let studentAgeInYear = studentAge.years();

            let studentAgeInMonth = studentAge.months();

            if (studentAgeInYear > 0 || studentAgeInMonth > 0) {
                return studentAgeInYear + " year " + studentAgeInMonth + " month";
            } else if (studentAgeInYear === 0 && studentAgeInMonth === 0) {
                return studentAgeInYear + " year " + studentAgeInMonth + " month";
            } else {
                return "-";
            }
        }
        return "-"
    };

    static assignPackageColors = (packageIndex) => {
        let colors = ["#59C069", "#FA91AA", "#00B6DA", "#7413BD"];
        packageIndex = packageIndex % colors.length;
        return colors[packageIndex];
    }

    static checkIfDuplicateSlots = (slots) => {
        let dayTimeActivityMap = new Map();
        for (const element of slots) {
            let key = element.day + element.startTime + element.endTime + element.activityId;
            if (dayTimeActivityMap.has(key)) {
                notification("error", formatMsg("error.duplicateSlots") + " " + (element.day || dayjs(element.date).format("DD MMM,YYYY")));
                return false;
            } else {
                dayTimeActivityMap.set(key, 1);
            }
        }
        return true;
    }


    static getLanguage = () => {
        return [
            { label: "English", value: "en" }, { label: "Arabic", value: "ar" }
        ]
    }

    static isParent = (firebase) => {
        return firebase?.user?.userType?.toLowerCase() === "parent"
    }

    static getBookingStatusNane = (bookingStatus) => {
        let name = "booked";
        switch (bookingStatus) {
            case "ENROLLED":
                name = "enrolled";
                break;
            case "WAITLISTED":
                name = "waitlisted"
                break;
            case "CANCELLED":
                name = "cancelled";
                break;
            case "APPROVAL_REQUESTED":
                name = "approvalRequested";
                break;
            case "ENROLLMENT_PENDING":
                name = "enrolledPending";
                break;
            case "COMPLETED":
                name = "completed";
                break;
        }
        return name;
    }

    static getCountryCode() {
        const zone = dayjs.tz.guess();
        if (timeZones && zone && timeZones.countries) {
            const countryCode = Object.keys(timeZones.countries).find(key =>
                timeZones.countries[key].zones.includes(zone)
            );
            return countryCode?.toLowerCase() || 'us';
        }
        return 'us';
    }

    static getStaffRole() {
        return ["Teacher", "Super Admin", "Helper", "Administrator", "Center Head", "Principal", "Senior Teacher", "Finance Admin"]
    }

    static getStringValueForForm(string) {
        return string && string.trim() ? string.trim() : null
    }

    static notifyBugsnag(err, showNotification?: boolean) {
        if (showNotification)
            notification("error", err?.response?.body?.message ? err?.response?.body?.message : formatMsg("error.occured"))
        bugsnagClient.notify(err);
    }
    static getCurrency = (firebase) => {
        switch (firebase.schoolConfig.currencyCode?.toLowerCase()) {
            case "usd":
                return "$"
            case "rupee":
                return "₹"
        }
        return "$"
    }

    static renderBreadCrumb = (currPageText) => {
        const router = useRouter();
        return <Row typeof="flex" align="middle" style={{ marginBottom: "12px" }}>
            <span
                style={{
                    color: "#8D8D8D",
                    cursor: "pointer"
                }}
                onClick={() => router.back()}>{formatMsg("goBack")}
                <RightOutlined style={{ margin: "0px 8px 0px 8px" }} />
            </span>
            <span>{formatMsg(currPageText)}</span>
        </Row>
    }

    static checkDiscountActive(record) {
        if (!record.active) {
            return false
        }
        if (record?.discount?.toLowerCase() === "conditional") {
            if (record?.discountRule?.toLowerCase() === "student") {
                return record.redemptions ? true : false
            }
            else {
                let newDate = dayjs(record?.registrationDate).startOf("day").add(record?.ruleValue, "day");
                return newDate.isAfter(dayjs().valueOf(), "day") ? true : false
            }
        }
        return true;
    }

    static getFilteredDiscount(list) {
        return list?.filter((item) => {
            return Helper.checkDiscountActive(item);
        })
    }

    static getPasswordRegex() {
        return /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]).{8,}$/
    }

    static calulateDuration(startDate, endDate, frequency) {
        let duration;
        switch (frequency) {
            case "MONTH":
                duration = dayjs(endDate).diff(dayjs(startDate), "month") + 1;
                return duration > 1 ? `Booked for : ${duration} months` : `Booked for : ${duration} month`
            case "WEEK":
                duration = dayjs(endDate).diff(dayjs(startDate), "week") + 1;
                return duration > 1 ? `Booked for : ${duration} weeks` : `Booked for : ${duration} week`;
            case "DAILY":
            case "CUSTOM":
                duration = dayjs(endDate).diff(dayjs(startDate), "day") + 1;
                return duration > 1 ? `Booked for : ${duration} days` : `Booked for : ${duration} day`;
            case "YEARLY":
                duration = dayjs(endDate).diff(dayjs(startDate), "year") + 1;
                return duration > 1 ? `Booked for : ${duration} years` : `Booked for : ${duration} year`;
        }
    }
    static calulateDurationForTable(startDate, endDate, frequency) {
        let duration;
        switch (frequency) {
            case "MONTH":
                duration = dayjs(endDate).diff(dayjs(startDate), "month");
                return duration > 1 ? `Booked for : ${duration} months` : `Booked for : ${duration} month`
            case "WEEK":
                duration = dayjs(endDate).diff(dayjs(startDate), "week");
                return duration > 1 ? `Booked for : ${duration} weeks` : `Booked for : ${duration} week`;
            case "DAILY":
            case "CUSTOM":
                duration = dayjs(endDate).diff(dayjs(startDate), "day");
                return duration > 1 ? `Booked for : ${duration} days` : `Booked for : ${duration} day`;
            case "YEARLY":
                duration = dayjs(endDate).diff(dayjs(startDate), "year");
                return duration > 1 ? `Booked for : ${duration} years` : `Booked for : ${duration} year`;
        }
    }

    static calulateDurationCount(startDate, endDate, frequency) {
        let duration;
        switch (frequency) {
            case "ENTIRE_PACKAGE_DURATION":
                return 1;
            case "MONTH":
                return duration = dayjs(endDate).diff(dayjs(startDate), "month") + 1;
            case "WEEK":
                return duration = dayjs(endDate).diff(dayjs(startDate), "week") + 1;
            case "DAILY":
            case "CUSTOM":
                return duration = dayjs(endDate).diff(dayjs(startDate), "day") + 1;
            case "YEARLY":
                return duration = dayjs(endDate).diff(dayjs(startDate), "year") + 1;
        }
    }

    static calculateDueDate(packageObj, intervalEndDate, frequency) {
        console.log("packageObj", packageObj)
        if (frequency === "DAILY" || frequency === "CUSTOM") {
            return intervalEndDate;
        }
        switch (packageObj.dueOnCycle.toLowerCase()) {
            case "before":
                return dayjs(intervalEndDate).subtract(packageObj.dueOnDays, "day");
            case "after":
                return dayjs(intervalEndDate).add(packageObj.dueOnDays, "day");
        }
    }


}